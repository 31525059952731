import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { bancoStore, contaBancariaFormaPagamentoStore, contaBancariaStore, mainStore, } from '@/store';
import { newContaBancaria } from '@/interfaces/contaBancaria';
import SearchBancoComponent from '@/components/SearchBancoComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import ContaBancariaTransferirComponent from '@/components/ContaBancariaTransferirComponent.vue';
// import PixIntegradorConfiguracaoComponent from '@/components/PixIntegradorConfiguracaoComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import _ from 'lodash';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.loading = false;
        this.selectedFormasDePagamentos = [];
        this.saldo_valor = null;
        this.saldo_data = null;
        this.search = '';
        this.selectedItem = {};
        this.bancoSelected = null;
        this.validationObj = {};
        this.selectPeriodDate = [];
        this.tipoMovimentacaoSelected = null;
        this.contaBancariaMovimentacoes = [];
        this.formasDePagamentos = [];
        this.tabelaRef = null;
        this.tab = 'tab_historico';
        this.contaBancariaMovimentacoesHeaders = [
            {
                show: true,
                table: 'conta_bancaria_movimentacao',
                text: 'Data/Hora',
                type: 'timestamp',
                value: 'data_hora',
            },
            {
                show: true,
                table: 'conta_bancaria_movimentacao',
                text: 'Descrição',
                type: 'str',
                value: 'descricao',
            },
            {
                show: true,
                table: 'conta_enviou',
                text: 'Conta que enviou (Transferência)',
                type: 'str',
                value: 'conta_enviou_nome',
            },
            {
                show: true,
                table: 'conta_recebeu',
                text: 'Conta que recebeu (Transferência)',
                type: 'str',
                value: 'conta_recebeu_nome',
            },
            {
                show: true,
                table: 'financeiro_parcela',
                text: 'Financeiro',
                type: 'str',
                value: 'id',
            },
            {
                show: true,
                table: 'conta_bancaria_movimentacao',
                text: 'Caixa',
                type: 'str',
                value: 'caixa_id',
            },
            {
                show: true,
                table: 'conta_bancaria_movimentacao',
                text: 'Valor',
                type: 'str',
                value: 'valor',
            },
        ];
    }
    get contasBancarias() {
        return contaBancariaStore.contasBancarias;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get bancosOptions() {
        return bancoStore.bancos;
    }
    get tipoMovimentacaoOptions() {
        return [
            { id: 1, text: 'Entrada' },
            { id: 2, text: 'Saída' },
            { id: 3, text: 'Transferência' },
        ];
    }
    async submit() {
        // @ts-ignore
        const validSearch = 
        // @ts-ignore
        await this.$refs.searchBancoComponent.$refs.observer.validate();
        const isValid = await this.$refs.observer.validate();
        if (!isValid && !validSearch) {
            // @ts-ignore
            await this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.bancoSelected) {
            this.entityObject.banco_id = this.bancoSelected.id;
        }
        if (this.id) {
            const result = await contaBancariaStore.updateContaBancaria(this.entityObject);
            if (result) {
                await contaBancariaFormaPagamentoStore.updateContaBancariaFormaPagamento({
                    data: this.selectedFormasDePagamentos.map((item) => {
                        return {
                            company_id: this.entityObject.company_id,
                            conta_bancaria_id: this.id,
                            forma_pagamento_id: item,
                        };
                    }),
                    contaBancariaId: this.id,
                });
            }
        }
        else {
            const result = await contaBancariaStore.createContaBancaria({
                conta: this.entityObject,
            });
            this.id = result;
            if (this.id) {
                await contaBancariaFormaPagamentoStore.createContaBancariaFormaPagamento(this.selectedFormasDePagamentos.map((item) => {
                    return {
                        company_id: this.userProfile.company.id,
                        conta_bancaria_id: this.id,
                        forma_pagamento_id: item,
                    };
                }));
                this.entityObject.id = result;
            }
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-contas-bancarias' });
    }
    async onSaveMovimentacao() {
        this.loading = true;
        this.contaBancariaMovimentacoes =
            await contaBancariaStore.getContaBancariaMovimentacoes({ id: parseInt(this.id, 0) });
        this.entityObject = await contaBancariaStore.getContaBancaria(parseInt(this.id, 0));
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-contasapagar-edit', params: { id } });
    }
    routeEditCaixa(id) {
        this.$router.push({ name: 'main-caixas-edit', params: { id } });
    }
    async buscarMovimentacoes() {
        this.contaBancariaMovimentacoes =
            await contaBancariaStore.getContaBancariaMovimentacoes({
                id: parseInt(this.id, 0),
                tipos: this.tipoMovimentacaoSelected,
                data_inicial: this.selectPeriodDate[0],
                data_final: this.selectPeriodDate[1]
            });
    }
    async mounted() {
        this.loading = true;
        this.formasDePagamentos =
            await contaBancariaFormaPagamentoStore.getFormasPagamentosDisponiveis();
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await contaBancariaStore.getContaBancaria(parseInt(this.id, 0));
            this.contaBancariaMovimentacoes =
                await contaBancariaStore.getContaBancariaMovimentacoes({ id: parseInt(this.id, 0) });
            this.selectedFormasDePagamentos = _.filter(this.formasDePagamentos, (item) => !item.livre && item.conta_bancaria_id == this.id).map((item) => item.id);
            // @ts-ignore
            this.bancoSelected = this.entityObject.banco;
        }
        else {
            this.entityObject = newContaBancaria();
        }
        this.formasDePagamentos = _.filter(this.formasDePagamentos, (item) => item.livre || (!item.livre && this.id == item.conta_bancaria_id));
        this.loading = false;
        setTimeout(() => {
            // @ts-ignore
            this.tabelaRef = this.$refs.tableMovimentacoesBancarias;
        }, 300);
    }
};
Create = __decorate([
    Component({
        components: {
            ContaBancariaTransferirComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            ImprimirTabelaComponent,
            // PixIntegradorConfiguracaoComponent,
            SaveButton,
            SearchBancoComponent,
            ValidationObserver,
            ValidationProvider,
            PeriodSelectorComponent,
        },
    })
], Create);
export default Create;
